.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  font-size: 19px;
  line-height: 1.47368421;
  background-color: #711C2F;
  cursor: pointer;
  color: #fff;
  padding: 10px 46px 10px 14px;
  -webkit-appearance: none;
  border-radius: 0;
  border: none;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  background-size: 18px 18px;
  background-position: right 14px center;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-right-white.svg);
  transition: all 0.3s;
}
.button:hover,
.button:focus {
  color: #711C2F;
  background-color: #D1E0B1;
  background-image: url(/images/arrow-right-red.svg);
}
.button:before {
  display: none;
}
@font-face {
  font-family: "MaisonNeueLight";
  src: url("/extras/fonts/MaisonNeueWEB-Light.woff2") format("woff2"), url("/extras/fonts/MaisonNeueWEB-Light.woff") format("woff");
}
@font-face {
  font-family: "MaisonNeueDemi";
  src: url("/extras/fonts/MaisonNeueWEB-Demi.woff2") format("woff2"), url("/extras/fonts/MaisonNeueWEB-Demi.woff") format("woff");
}
* {
  margin: 0;
  padding: 0;
  outline: 0;
  border: none;
  background: none;
  font-size: 100%;
}
*::selection {
  background: #711C2F;
  color: #fff;
}
.hidden,
.cb-invisible {
  display: none !important;
}
li {
  list-style-position: inside;
}
img,
audio,
video,
iframe {
  float: left;
  width: 100%;
}
strong {
  font-weight: normal;
  font-family: 'MaisonNeueDemi', sans-serif;
}
sup,
sub {
  position: relative;
  vertical-align: baseline;
  top: -0.8em;
  font-size: 70%;
}
sub {
  top: 0.4em;
}
address {
  font-style: normal;
}
input,
textarea {
  border-radius: 0;
  font-size: 19px;
  font-family: 'MaisonNeueLight', sans-serif;
  line-height: 1.47368421;
}
.unit caption {
  display: none;
}
.flag {
  background: #711C2F;
  color: #fff;
}
img.cb-loading {
  background-size: 30px 30px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-loader.svg);
}
.area {
  float: left;
  width: 100%;
}
#view .area:empty {
  display: none;
}
.unit {
  float: left;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 24px;
  position: relative;
}
@media (max-width: 1024px) {
  .unit {
    margin-top: 24px;
  }
}
@media (max-width: 767px) {
  .unit {
    margin-top: 24px;
  }
}
@media (max-width: 1024px) {
  .unit {
    margin-bottom: 24px;
  }
}
@media (max-width: 767px) {
  .unit {
    margin-bottom: 24px;
  }
}
.unit .head,
.unit .body,
.unit .foot {
  float: left;
  width: 100%;
}
.unit.fold div.less,
.unit.fold div.more {
  float: left;
  width: 100%;
}
.unit.fold .ctrl {
  float: left;
  width: 100%;
}
.part {
  float: left;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
  position: relative;
}
@media (max-width: 1024px) {
  .part {
    margin-top: 16px;
  }
}
@media (max-width: 767px) {
  .part {
    margin-top: 16px;
  }
}
@media (max-width: 1024px) {
  .part {
    margin-bottom: 16px;
  }
}
@media (max-width: 767px) {
  .part {
    margin-bottom: 16px;
  }
}
.pict a {
  float: left;
  width: 100%;
}
.pict img {
  width: 100%;
}
.pict img:not(.svg) {
  width: auto;
  max-width: 100% !important;
}
#root#root#root img.zoom {
  display: none !important;
}
.cb-image-figure {
  float: left;
  width: 100%;
}
.cb-image-container {
  float: left;
  width: 100%;
  position: relative;
}
.cb-image-caption {
  float: left;
  width: 100%;
  margin-top: 16px;
  font-size: 15px;
  line-height: 1.46666667;
}
#expo .cb-image-figure {
  height: 100%;
}
#expo .cb-image-container {
  height: 100% !important;
}
#disp.zoom .cb-image-figure {
  height: 100%;
}
#disp.zoom .cb-image-container {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom .cb-image-caption {
  display: none;
}
.cb-album .cb-image-figure {
  opacity: 1 !important;
  display: block !important;
}
.cb-album .cb-image-container {
  position: static;
}
.cb-album .cb-image-caption {
  display: none;
}
div.load {
  background: none !important;
}
.show a {
  float: left;
  width: 100%;
  display: block;
  border: none !important;
}
#edit .line {
  padding-top: 5px;
  padding-bottom: 5px;
}
.line hr {
  float: left;
  width: 100%;
  border-bottom: 1px solid #000;
}
.line hr.bold {
  border-bottom-width: 2px;
}
.line hr.dash {
  border-bottom-style: dashed;
}
.line hr.spot {
  border-bottom-style: dotted;
}
#edit div.code {
  position: relative;
  min-height: 30px;
}
#edit div.code:after {
  content: 'Code';
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 2;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background: #aaa;
  opacity: 0.6;
  color: #fff;
  text-transform: uppercase;
  font-weight: normal;
  font-family: 'MaisonNeueDemi', sans-serif;
  font-size: 11px;
  letter-spacing: 0.2em;
  padding: 0 20px;
}
.cb-code-executable {
  float: left;
  width: 100%;
}
.cb-service {
  float: left;
  width: 100%;
  margin: 5px 0;
}
.cb-service:first-child {
  margin-top: 0;
}
.cb-service:last-child {
  margin-bottom: 0;
}
.cb-youtube iframe,
.cb-vimeo iframe {
  height: 100%;
  background-color: #fff;
}
.text-section {
  float: left;
  width: 100%;
}
.list {
  float: left;
  width: 100%;
}
.list--bullet li {
  list-style: none;
  padding-left: 24px;
  position: relative;
  margin-top: 6px;
}
.list--bullet li:first-child {
  margin-top: 0;
}
.list--bullet li:before {
  content: '–';
  position: absolute;
  left: 0;
  top: 0;
}
.list--numbered {
  counter-reset: numberedList;
}
.list--numbered li {
  list-style: none;
  padding-left: 24px;
  position: relative;
}
.list--numbered li:before {
  content: counter(numberedList) ".";
  counter-increment: numberedList;
  position: absolute;
  left: 0;
  top: 0;
}
#expo {
  float: left;
  width: 100%;
  position: relative;
}
#slides {
  float: left;
  width: 100%;
  height: 0 !important;
  max-width: 100% !important;
  max-height: 100% !important;
  padding-bottom: 33.671875%;
  position: relative;
}
@media (max-width: 950px) {
  #slides {
    padding-bottom: 320px;
  }
}
#slides .slide {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
}
#slides a {
  display: block;
  width: 100%;
  height: 100%;
}
#slides a:before {
  display: none;
}
#slides a.null {
  cursor: default;
}
.cb-slides .cb-image-figure {
  height: 100%;
}
.cb-slides .cb-image-container {
  height: 100% !important;
}
#slides img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
  font-family: 'object-fit: cover; object-position: 50% 50%;';
}
#expo div.link {
  display: none;
  float: left;
  width: 100%;
  height: 0;
}
#expo a.link {
  position: absolute;
  top: 50%;
  z-index: 2;
  width: 30px;
  height: 50px;
  transform: translateY(-50%);
  background: #711C2F;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
}
#expo a.link.prev {
  left: 20px;
}
#expo a.link.next {
  right: 20px;
}
table.link,
table.link tbody {
  float: left;
  display: block;
  width: 100%;
  height: 0;
}
.cb-index-all {
  display: block;
  position: absolute;
  left: 0;
  bottom: 20px;
  z-index: 2;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.cb-index-all td {
  float: left;
  display: block;
  margin: 0 10px;
}
.cb-index-all td.init {
  margin-left: 0;
}
.cb-index-all td.exit {
  margin-right: 0;
}
.cb-index-all a {
  display: block;
  width: 16px;
  height: 16px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background: #fff;
  border-radius: 16px;
  transition: all 0.4s;
}
.cb-index-all td.this a {
  background: #711C2F;
}
.cb-index-some {
  display: block;
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 2;
}
.cb-index-some td {
  float: left;
  display: block;
  color: #fff;
}
.cb-index-some td:first-child:after {
  display: inline-block;
  content: '/';
  margin: 0 5px;
}
.cb-index-some a {
  color: #fff;
}
.cb-index-some a:hover,
.cb-index-some a:focus {
  color: #fff;
}
#over {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5000;
  width: 100%;
  height: 100% !important;
  background-color: rgba(0, 0, 0, 0.8);
}
#disp {
  position: absolute;
  z-index: 5001;
  left: 50% !important;
  transform: translateX(-50%);
  width: 1000px !important;
  max-width: 90%;
  min-height: 100vh;
  display: flex !important;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  padding: 80px 0 !important;
  font-size: 14px;
  font-family: 'MaisonNeueLight', sans-serif;
  line-height: 1.42857143;
  color: #000;
}
@media (max-width: 767px) {
  #disp {
    padding: 20px 0 !important;
  }
}
.disp-wrapper {
  float: left;
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
  padding: 40px;
}
@media (max-width: 767px) {
  .disp-wrapper {
    padding: 15px;
  }
}
#disp form {
  float: left;
}
.disp-wrapper > form {
  width: 100%;
}
@media (max-width: 767px) {
  #disp form {
    width: 100%;
  }
}
#disp .fail {
  color: #ae2121;
}
#disp .part {
  margin-top: 4px;
  margin-bottom: 4px;
}
#disp a.mail {
  float: left;
  min-height: 26px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
#disp .head,
#disp .body,
#disp .foot {
  float: left;
  width: 100%;
  position: relative;
}
#disp .body {
  margin: 20px 0;
}
#disp .foot form + form {
  float: right;
}
#disp h2 {
  font-size: 14px;
  line-height: 1.71428571;
  font-weight: normal;
  text-transform: uppercase;
  color: #711C2F;
  width: 100%;
  box-sizing: border-box;
  padding-right: 30px;
}
#disp h3 {
  color: #711C2F;
  font-size: 16px;
  font-weight: normal;
  font-family: 'MaisonNeueDemi', sans-serif;
  padding: 20px 0;
  width: 100%;
}
#disp .head .ctrl {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
#disp .head .ctrl > div {
  float: left;
}
#disp .head .ctrl a {
  float: left;
  display: block;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  width: 26px;
  height: 26px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/icon-close-black.svg);
}
#disp .foot input,
#disp .foot a {
  float: left;
  font-size: 19px;
  line-height: 1.47368421;
  background-color: #711C2F;
  cursor: pointer;
  color: #fff;
  padding: 10px 46px 10px 14px;
  -webkit-appearance: none;
  border-radius: 0;
  border: none;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  background-size: 18px 18px;
  background-position: right 14px center;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-right-white.svg);
  transition: all 0.3s;
  line-height: 1.42857143;
}
#disp .foot input:hover,
#disp .foot a:hover,
#disp .foot input:focus,
#disp .foot a:focus {
  color: #711C2F;
  background-color: #D1E0B1;
  background-image: url(/images/arrow-right-red.svg);
}
#disp .foot input:before,
#disp .foot a:before {
  display: none;
}
#disp .foot input.next,
#disp .foot a.next {
  float: right;
}
#disp.mail .foot input {
  float: right;
}
@media (max-width: 767px) {
  #disp .foot input,
  #disp .foot a {
    width: 100%;
    margin: 5px 0;
  }
  #disp .foot input:first-child,
  #disp .foot a:first-child {
    margin-top: 0;
  }
  #disp .foot input:last-child,
  #disp .foot a:last-child {
    margin-bottom: 0;
  }
}
#disp fieldset {
  float: left;
  width: 100%;
  position: relative;
  margin: 4px 0;
}
#disp fieldset.tiny {
  width: 48%;
}
#disp .head fieldset,
#disp .foot fieldset {
  margin: 0;
}
@media (max-width: 767px) {
  #disp fieldset.tiny {
    width: 100%;
  }
}
#disp label.name {
  float: left;
  width: 30%;
  margin-right: 4%;
  min-height: 26px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 767px) {
  #disp label.name {
    width: 100%;
    margin-right: 0;
  }
}
#disp input.text,
#disp textarea {
  float: right;
  width: 66%;
  background-color: #F0F0F0;
  min-height: 26px;
  box-sizing: border-box;
  padding: 2px 10px;
}
#disp input.text.fail,
#disp textarea.fail {
  border: 2px solid #ae2121;
}
#disp textarea {
  min-height: 100px;
  padding: 6px 10px;
}
@media (max-width: 767px) {
  #disp input.text,
  #disp textarea {
    width: 100%;
  }
}
#disp select {
  float: right;
  width: 66%;
  background-color: #F0F0F0;
  height: 26px;
}
@media (max-width: 767px) {
  #disp select {
    width: 100%;
  }
}
#disp input + label,
#disp input + label {
  display: block;
  padding-left: 30px;
}
#disp input.radio,
#disp input.checkbox {
  position: absolute;
  left: 0;
  top: 4px;
  z-index: 2;
  height: 20px;
  width: 20px;
}
.cb-sitemap-target {
  float: left;
  margin-bottom: 20px;
  color: #fff;
  padding: 5px 10px;
  background-color: #ae2121;
}
.cb-sitemap-contents {
  float: left;
  width: 100%;
}
.cb-sitemap-contents li {
  list-style: none;
  padding-left: 20px;
  position: relative;
}
.cb-sitemap-contents li:before {
  content: '•';
  position: absolute;
  left: 0;
  top: 0;
}
.cb-zoom-indicator {
  position: absolute;
  z-index: 2;
  right: 15px;
  bottom: 15px;
  width: 36px;
  height: 36px;
  border-radius: 30px;
  background-color: rgba(0, 0, 0, 0.5);
  background-size: 18px 18px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-zoom-white.svg);
  opacity: 0;
  transform: scale(0);
  transition: all 0.3s cubic-bezier(0.05, 0.8, 0.5, 1);
}
a.zoom .cb-zoom-indicator {
  opacity: 1;
  transform: scale(1);
}
@media (max-width: 1024px) {
  .cb-zoom-indicator {
    right: 10px;
    bottom: 10px;
    width: 24px;
    height: 24px;
    background-size: 12px 12px;
    opacity: 1;
    transform: scale(1);
  }
}
#disp.zoom {
  position: fixed;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  max-width: 100%;
  height: 100%;
  background: none;
  padding: 0 !important;
  display: block !important;
  transform: none;
}
#disp.zoom .disp-wrapper {
  padding: 0;
  background: none;
  height: 100%;
}
#disp.zoom div.head h2 {
  display: none;
}
#disp.zoom div.head div.ctrl {
  position: absolute;
  right: 30px;
  top: 30px;
  z-index: 2;
}
@media (max-width: 767px) {
  #disp.zoom div.head div.ctrl {
    top: 20px;
    right: 20px;
  }
}
#disp.zoom div.head div.ctrl > div {
  float: left;
  margin: 0 10px;
}
#disp.zoom div.head div.ctrl > div:first-child {
  margin-left: 0;
}
#disp.zoom div.head div.ctrl > div:last-child {
  margin-right: 0;
}
#disp.zoom div.head div.ctrl a {
  float: left;
  display: block;
  width: 20px;
  height: 20px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-white.svg);
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
}
#disp.zoom div.head div.ctrl .next a {
  background-image: url(/images/cb-default/cb-arrow-right-white.svg);
}
#disp.zoom div.head div.ctrl .quit a {
  background-image: url(/images/cb-default/cb-cross-white.svg);
  background-size: 16px 16px;
}
#disp.zoom div.head div.ctrl a:hover {
  transform: scale(1.1);
}
#disp.zoom div.head div.ctrl a:active {
  transform: scale(1);
}
#disp.zoom div.body {
  float: left;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 80px 30px;
  margin: 0;
}
@media (max-width: 767px) {
  #disp.zoom div.body {
    padding: 60px 20px;
  }
}
#disp.zoom div.body > div {
  float: left;
  width: 100% !important;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom div.body a {
  float: left;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom div.body img {
  float: left;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
#disp.zoom div.foot {
  display: none;
}
.ie11 #disp.zoom {
  position: absolute;
}
.ie11 #disp.zoom div.body,
.ie11 #disp.zoom div.body > div,
.ie11 #disp.zoom div.body a {
  height: auto;
  display: block;
}
.ie11 #disp.zoom div.body img {
  float: none;
  position: relative;
  height: auto;
  display: block;
  margin: 0 auto;
}
#disp.srch {
  display: block !important;
}
#disp.srch h2 {
  display: none;
}
#disp.srch .disp-wrapper {
  background: none;
  padding: 0;
}
#disp.srch .head .ctrl a {
  background-image: url(/images/cb-default/cb-cross-white.svg);
}
#disp.srch div.body {
  margin: 0;
}
#disp.srch fieldset {
  margin: 0;
}
#disp.srch label.name {
  display: none;
}
#disp.srch input.text {
  font-family: 'MaisonNeueLight', sans-serif;
  background-color: transparent;
  margin-top: 40px;
  width: 100%;
  color: #fff;
  font-size: 50px;
  line-height: 1;
  border-bottom: 1px solid #fff;
  padding: 20px 0;
  -webkit-font-smoothing: antialiased;
  -webkit-appearance: none;
}
@media (max-width: 767px) {
  #disp.srch input.text {
    font-size: 18px;
  }
}
.cb-result {
  float: left;
  width: 100%;
  color: #fff;
  font-size: 18px;
  text-align: left;
  -webkit-font-smoothing: antialiased;
}
.cb-result p {
  margin-top: 40px;
}
.cb-result a {
  color: #fff;
  text-decoration: none;
}
.cb-result a:hover {
  color: #fff;
  text-decoration: underline;
}
.cb-result table {
  float: left;
  width: 100%;
  border-collapse: collapse;
}
.cb-result tbody {
  width: 100%;
}
.cb-result th {
  padding-top: 20px;
  font-size: 24px;
  line-height: 1;
  text-align: left;
}
.cb-result th:first-child {
  padding-right: 40px;
  width: 80px;
}
.cb-result td {
  padding-top: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}
.cb-result th,
.cb-result td {
  vertical-align: middle;
}
@media (max-width: 767px) {
  .cb-result {
    font-size: 14px;
  }
  .cb-result th {
    padding-top: 10px;
    font-size: 18px;
  }
  .cb-result th:first-child {
    padding-right: 10px;
    width: 54px;
  }
  .cb-result td {
    padding-bottom: 10px;
    padding-top: 5px;
  }
}
.unit.form form {
  float: left;
  width: 100%;
}
.cb-form-required {
  float: left;
  width: 100%;
}
.unit.form .part.must {
  font-size: 16px;
  line-height: 1.5;
  color: #928D8A;
}
.unit.form .part.word + .part.word {
  margin-top: 0;
}
.unit.form .part.text p.norm {
  color: #000;
}
.unit.form .part.text p.norm a.open {
  font-size: 16px;
  line-height: 1.5;
  color: #928D8A;
  border-color: #928D8A;
}
.unit.form .part.text p.norm a.open:hover,
.unit.form .part.text p.norm a.open:focus {
  color: #711C2F;
  border-color: #711C2F;
}
.unit.form fieldset {
  float: left;
  width: 100%;
  position: relative;
}
.unit.form div.ctrl {
  float: left;
  width: 100%;
}
.unit.form div.ctrl.fail {
  box-sizing: border-box;
  padding: 5px;
  background-color: #d4bbc1;
}
@media (min-width: 768px) {
  .unit.form .tile div.ctrl {
    float: right;
    width: 66%;
  }
}
.unit.form .name {
  float: left;
  width: 100%;
  padding-bottom: 5px;
}
@media (min-width: 768px) {
  .unit.form .tile .name {
    width: 30%;
    min-height: 50px;
    padding-top: 11px;
    padding-bottom: 11px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
  }
}
.unit.form input.text,
.unit.form textarea {
  float: left;
  width: 100%;
  box-sizing: border-box;
  background: transparent;
  min-height: 50px;
  padding: 5px 10px;
  -webkit-appearance: none;
  border: 1px solid #000;
}
.unit.form input.text.fail,
.unit.form textarea.fail {
  background-color: #d4bbc1;
}
.unit.form textarea {
  padding: 11px 10px;
}
@media (min-width: 768px) {
  .unit.form .tile input.text,
  .unit.form .tile textarea {
    float: right;
    width: 66%;
  }
}
.unit.form div.tick div.ctrl > div {
  float: left;
  width: 100%;
  position: relative;
  padding: 4px 0;
}
.unit.form div.tick.pile:has(.checkbox) + div.tick.pile:has(.checkbox) {
  margin-top: -16px;
}
.unit.form div.tick.pile:has(.checkbox) + div.tick.pile:has(.checkbox) span.name {
  display: none;
}
@media (min-width: 768px) {
  .unit.form div.tick.tile div.ctrl > div {
    padding-top: 11px;
    padding-bottom: 11px;
  }
}
.unit.form div.tick label {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding-left: 26px;
}
.unit.form div.tick input {
  position: absolute;
  left: 0;
  top: 4px;
  width: 18px;
  margin-right: 6px;
  height: 28px;
  vertical-align: top;
}
@media (max-width: 767px) {
  .unit.form div.tick input {
    height: 25px;
  }
}
@media (min-width: 768px) {
  .unit.form div.tick.tile input {
    top: 11px;
  }
}
div.tick span.name {
  font-weight: normal;
  font-family: 'MaisonNeueDemi', sans-serif;
}
div.tick.tile {
  margin-top: -16px !important;
}
@media (max-width: 1024px) {
  div.tick.tile {
    margin-top: -16px;
  }
}
@media (max-width: 767px) {
  div.tick.tile {
    margin-top: -16px;
  }
}
div.tick.tile span.name {
  display: none !important;
}
div.tick.tile div.ctrl {
  float: left;
  width: 100% !important;
}
div.tick.tile div.ctrl > div {
  padding: 4px 0 !important;
}
div.tick.tile input {
  top: 4px !important;
}
.unit.form select {
  float: left;
  width: 100%;
  font-family: 'MaisonNeueLight', sans-serif;
  font-size: 19px;
  line-height: 1.47368421;
  background: transparent;
  height: 50px;
  padding: 5px;
  border: 1px solid #000;
}
.unit.form .ship fieldset > div {
  float: left;
  width: 100%;
}
.unit.form .ship fieldset > div.fail {
  box-sizing: border-box;
  padding: 5px;
  background-color: #d4bbc1;
}
.unit.form .ship div.chop {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.unit.form .ship div.chop input {
  float: left;
  display: block;
  font-size: 12px;
}
.unit.form .ship div.chop span {
  float: left;
}
.unit.form .ship div.chop a {
  float: left;
  display: block;
  margin-left: 10px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  width: 12px;
  height: 12px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-cross-black.svg);
}
@media (min-width: 768px) {
  .unit.form .ship.tile fieldset > div {
    float: right;
    width: 66%;
    min-height: 50px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
  }
}
.unit.form input.submit {
  float: right;
  margin-top: 16px;
  margin-bottom: 16px;
  font-size: 19px;
  line-height: 1.47368421;
  background-color: #711C2F;
  cursor: pointer;
  color: #fff;
  padding: 10px 46px 10px 14px;
  -webkit-appearance: none;
  border-radius: 0;
  border: none;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  background-size: 18px 18px;
  background-position: right 14px center;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-right-white.svg);
  transition: all 0.3s;
}
@media (max-width: 1024px) {
  .unit.form input.submit {
    margin-top: 16px;
  }
}
@media (max-width: 767px) {
  .unit.form input.submit {
    margin-top: 16px;
  }
}
@media (max-width: 1024px) {
  .unit.form input.submit {
    margin-bottom: 16px;
  }
}
@media (max-width: 767px) {
  .unit.form input.submit {
    margin-bottom: 16px;
  }
}
.unit.form input.submit:hover,
.unit.form input.submit:focus {
  color: #711C2F;
  background-color: #D1E0B1;
  background-image: url(/images/arrow-right-red.svg);
}
.unit.form input.submit:before {
  display: none;
}
.calendar {
  position: absolute !important;
  z-index: 2;
  left: 34% !important;
  top: 100% !important;
  right: unset !important;
  background: #fff;
  width: 200px;
  margin: 10px 0 0 0 !important;
  box-shadow: 0 0 6px rgba(160, 160, 160, 0.4);
  font-size: 14px;
  line-height: 1.14285714;
  padding: 20px;
}
.calendar table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
.calendar th,
.calendar td {
  text-align: center;
  padding: 2px;
}
.calendar tr.month th {
  padding-bottom: 10px;
}
.calendar tr.month th:first-child a,
.calendar tr.month th:last-child a {
  float: left;
  display: block;
  width: 16px;
  height: 16px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-black.svg);
}
.calendar tr.month th:last-child a {
  float: right;
  background-image: url(/images/cb-default/cb-arrow-right-black.svg);
}
.calendar td.today a {
  color: #000;
  text-decoration: underline;
}
.unit.form .recaptcha-info {
  color: #aaa;
}
.unit.form .recaptcha-info a {
  color: #aaa;
  border: none !important;
}
.unit.form .recaptcha-info a:before {
  display: none;
}
.unit.form .recaptcha-info a:hover,
.unit.form .recaptcha-info a:focus {
  text-decoration: underline;
}
.unit.form .part.fail {
  font-size: 16px;
  line-height: 1.25;
  border-left: 4px solid #711C2F;
  box-sizing: border-box;
  padding: 20px;
  background-color: #d4bbc1;
  color: #711C2F;
}
.unit.form div.cb-form-sent {
  float: left;
  width: 66%;
  margin-left: 34%;
  margin-top: 16px;
  margin-bottom: 16px;
}
@media (max-width: 1024px) {
  .unit.form div.cb-form-sent {
    margin-top: 16px;
  }
}
@media (max-width: 767px) {
  .unit.form div.cb-form-sent {
    margin-top: 16px;
  }
}
@media (max-width: 1024px) {
  .unit.form div.cb-form-sent {
    margin-bottom: 16px;
  }
}
@media (max-width: 767px) {
  .unit.form div.cb-form-sent {
    margin-bottom: 16px;
  }
}
.unit.form div.cb-form-sent a {
  float: right;
  color: #5abb55;
  text-decoration: none;
}
.unit.form div.cb-form-sent a:before {
  display: none;
}
#disp.two-step-verification div.body {
  overflow: hidden;
}
#disp.two-step-verification p {
  float: left;
  width: 100%;
}
.two-step-verification-container {
  float: left;
  width: 100%;
  min-width: 300px;
  margin-top: 10px;
  height: 350px;
  position: relative;
}
.two-step-verification-container a {
  font-size: 19px;
  line-height: 1.47368421;
  background-color: #711C2F;
  cursor: pointer;
  color: #fff;
  padding: 10px 46px 10px 14px;
  -webkit-appearance: none;
  border-radius: 0;
  border: none;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  background-size: 18px 18px;
  background-position: right 14px center;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-right-white.svg);
  transition: all 0.3s;
}
.two-step-verification-container a:hover,
.two-step-verification-container a:focus {
  color: #711C2F;
  background-color: #D1E0B1;
  background-image: url(/images/arrow-right-red.svg);
}
.two-step-verification-container a:before {
  display: none;
}
.two-step-verification__div {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: #fff;
  background-size: 100px 100px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-loader-black.svg);
}
.two-step-verification-container.loaded .two-step-verification__div {
  background-image: none;
}
.table {
  float: left;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
  font-size: 15px;
  line-height: 1.33333333;
}
.section--four .area .table a.open {
  text-transform: uppercase;
  cursor: pointer;
  color: #000;
  border: none !important;
}
.section--four .area .table a.open:before {
  display: none;
}
.section--four .area .table a.open:after {
  content: '↓';
}
.section--four .area .table a.open:hover,
.section--four .area .table a.open:focus {
  color: #711C2F;
}
.table-layout-fixed .table {
  table-layout: fixed;
}
.table tr {
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
}
.table-head tr {
  border-top: none;
}
.table th {
  font-weight: normal;
  padding: 14px 10px 13px;
  vertical-align: top;
}
.table th.init {
  padding-left: 0;
}
.table th.exit {
  padding-right: 0;
}
.table th.align-left {
  text-align: left;
}
.table th.align-center {
  text-align: center;
}
.table th.align-right {
  text-align: right;
}
.table--headline th {
  font-weight: normal;
  font-family: 'MaisonNeueDemi', sans-serif;
}
.table td {
  padding: 14px 10px 13px;
  vertical-align: top;
}
.table td.init {
  padding-left: 0;
}
.table td.exit {
  padding-right: 0;
}
.table td.align-left {
  text-align: left;
}
.table td.align-center {
  text-align: center;
}
.table td.align-right {
  text-align: right;
}
#edit .table td {
  border-left: 1px dashed #e0e0e0;
  border-right: 1px dashed #e0e0e0;
}
.table--footer .table-foot td {
  font-weight: normal;
  font-family: 'MaisonNeueDemi', sans-serif;
}
@media (max-width: 767px) {
  .part--table .table {
    display: block;
  }
  .part--table .table .table-head,
  .part--table .table .table-foot {
    display: none;
  }
  .part--table .table .table-body {
    display: block;
    width: 100%;
  }
  .part--table .table tr {
    float: left;
    width: 100%;
    display: block;
    box-sizing: border-box;
    padding: 11px 16px;
    background-color: #e5e3e1;
    border: none;
    text-align: left;
    margin-top: 9px;
  }
  .table-body .part--table .table tr:first-child {
    margin-top: 0;
  }
  .part--table .table .table-body tr:first-child {
    margin-top: 0;
  }
  .part--table .table td {
    float: left;
    display: block;
    width: 100%;
    padding: 5px 0;
    text-align: left;
  }
  .part--table .table.table--headline td {
    position: relative;
    box-sizing: border-box;
    padding-left: 52%;
  }
  .part--table .table.table--headline td:before {
    content: attr(data-title);
    position: absolute;
    left: 0;
    top: 0;
    width: 48%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    font-weight: normal;
    font-family: 'MaisonNeueDemi', sans-serif;
  }
}
html,
body {
  float: left;
  width: 100%;
  min-height: 100vh;
}
.homelink {
  float: left;
  height: 156px;
  margin: 32px 0;
}
.homelink:before {
  display: none;
}
@media (max-width: 1024px) {
  .homelink {
    height: 84px;
    margin: 24px 0;
  }
}
.logo {
  width: auto;
  height: 100%;
}
#head {
  float: left;
  width: 100%;
  margin-top: 110px;
  position: relative;
  z-index: 2;
}
.cb-layout3 #head {
  margin-top: 80px;
}
@media (max-width: 1024px) {
  .cb-layout3 #head {
    margin-top: 80px;
  }
}
@media (max-width: 767px) {
  .cb-layout3 #head {
    margin-top: 80px;
  }
}
@media (max-width: 767px) {
  #head {
    margin-top: 80px;
  }
}
.wrapper {
  float: left;
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
  font-family: 'MaisonNeueLight', sans-serif;
  font-size: 19px;
  line-height: 1.47368421;
  color: #000;
  overflow: hidden;
  hyphens: manual;
  letter-spacing: 0.4px;
}
.desk {
  *zoom: 1;
  margin: 0 auto;
  max-width: 100%;
  width: 1008px;
  position: relative;
  box-sizing: border-box;
  border: solid transparent;
  border-width: 0 20px;
}
.desk:before,
.desk:after {
  display: table;
  content: '';
}
.desk:after {
  clear: both;
}
.section--header .desk,
.section--two .desk,
.section--four .desk,
.section--footer .desk {
  width: 100%;
}
.section--three .desk {
  width: 1224px;
}
.section {
  float: left;
  width: 100%;
  position: relative;
}
.cb-layout3 .section--multimood {
  display: none;
}
.section--red {
  background-color: #711C2F;
  color: #fff;
}
.wrapper .section--red a {
  color: #fff;
  transition: all 0.3s;
}
.wrapper .section--red a:before {
  background-color: #fff;
  transition: all 0.3s;
}
.wrapper .section--red a:hover,
.wrapper .section--red a:focus {
  color: #D1E0B1;
}
.wrapper .section--red a:hover:before,
.wrapper .section--red a:focus:before {
  background-color: #D1E0B1;
}
.section--footer {
  background-color: #e5e3e1;
}
.content {
  float: left;
  width: 100%;
}
.content--footer {
  margin-top: 64px;
  margin-bottom: 64px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  font-size: 15px;
  line-height: 1.46666667;
}
@media (max-width: 1024px) {
  .content--footer {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}
@media (max-width: 767px) {
  .content--footer {
    display: block;
  }
}
.vcard {
  float: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
}
.vcard .org {
  font-weight: normal;
  font-family: 'MaisonNeueDemi', sans-serif;
}
.vcard a {
  margin-bottom: 4px;
  display: inline-block;
}
.vcard a:last-child {
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .vcard {
    width: 100%;
  }
}
.frutiger {
  float: left;
  width: 70px;
}
.vcardcontent {
  float: left;
  margin-top: 18px;
}
#services {
  float: left;
}
#services .meta {
  float: left;
  margin-right: 24px;
}
#services .meta:last-child {
  margin-right: 0;
}
@media (max-width: 767px) {
  #services {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 40px;
  }
  #services .meta {
    float: left;
    margin-right: 0;
    margin-bottom: 4px;
  }
  #services .meta:last-child {
    margin-bottom: 0;
  }
}
#social {
  position: absolute;
  left: 100%;
  margin-left: 56px;
  bottom: 0;
  z-index: 2;
  display: flex;
  align-items: center;
}
@media (max-width: 1024px) {
  #social {
    position: static;
    margin-top: 40px;
    margin-left: 0;
  }
}
#social:before {
  float: left;
  content: 'Folgen Sie uns';
  white-space: nowrap;
}
#social .meta {
  float: left;
  display: block;
  flex-shrink: 0;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  width: 16px;
  height: 16px;
  margin: 0 0 0 15px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/social-instagram-black.svg);
}
#social .meta:before {
  display: none;
}
#social .meta:hover {
  background-image: url(/images/social-instagram-red.svg);
}
#social .meta.service_facebook {
  width: 8px;
  background-image: url(/images/social-facebook-black.svg);
}
#social .meta.service_facebook:hover {
  background-image: url(/images/social-facebook-red.svg);
}
.colorimages {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
.cb-layout2 .colorimages,
.south--empty .colorimages {
  display: none;
}
@media (max-width: 1024px) {
  .colorimages {
    top: initial;
    bottom: 0;
    transform: translateY(40%);
  }
}
.colorimage {
  float: left;
  height: auto;
  display: none;
}
.colorimage--c1 {
  width: 400px;
  margin-top: -66px;
}
.c1 .colorimage--c1 {
  display: block;
}
@media (max-width: 1024px) {
  .colorimage--c1 {
    width: 300px;
    margin-top: 0;
  }
}
@media (max-width: 767px) {
  .colorimage--c1 {
    width: 260px;
  }
}
.colorimage--c2 {
  width: 346px;
  margin-top: -70px;
}
.c2 .colorimage--c2 {
  display: block;
}
@media (max-width: 1024px) {
  .colorimage--c2 {
    width: 220px;
    margin-top: 0;
  }
}
.toplink {
  position: fixed;
  bottom: 140px;
  right: 0;
  width: 40px;
  height: 40px;
  z-index: 200;
  background-size: 18px 18px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-top-black.svg);
  background-color: #e5e3e1;
  cursor: pointer;
  transform: translateX(100%);
  transition: all 0.4s;
}
@media (max-width: 767px) {
  .toplink {
    bottom: 40px;
  }
}
.toplink.cb-goto-top-visible {
  right: 86px;
  transform: translateX(0);
}
@media (max-width: 1519px) {
  .toplink.cb-goto-top-visible {
    right: 40px;
  }
}
@media (max-width: 767px) {
  .toplink.cb-goto-top-visible {
    right: 20px;
  }
}
.wrapper a {
  color: #000;
  text-decoration: none;
  position: relative;
  display: inline-block;
}
.wrapper a:before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: #000;
}
.wrapper a:hover,
.wrapper a:focus {
  color: #711C2F;
}
.wrapper a:hover:before,
.wrapper a:focus:before {
  background-color: #711C2F;
}
#head a,
.unit a {
  color: inherit;
  display: inline;
  border-bottom: 1px solid #000;
}
#head a:before,
.unit a:before {
  display: none !important;
}
#head a:hover,
.unit a:hover,
#head a:focus,
.unit a:focus {
  color: #711C2F;
  border-color: #711C2F;
}
.section--red #head a,
.section--red .unit a {
  border-color: #fff;
}
.section--red #head a:hover,
.section--red .unit a:hover,
.section--red #head a:focus,
.section--red .unit a:focus {
  color: #D1E0B1;
  border-color: #D1E0B1;
}
#head h1 a,
.unit h2 a {
  border-color: #711C2F;
}
#head h1 a:hover,
.unit h2 a:hover,
#head h1 a:focus,
.unit h2 a:focus {
  color: #000;
  border-color: #000;
}
h1 {
  font-weight: normal;
  font-size: 34px;
  line-height: 1.20588235;
  text-transform: uppercase;
  color: #711C2F;
}
.section--red h1 {
  color: #fff;
}
h2 {
  font-weight: normal;
  font-size: 28px;
  line-height: 1.21428571;
  text-transform: uppercase;
  color: #711C2F;
}
.wrapper h2 a {
  color: #711C2F;
  transition: all 0.3s;
}
.wrapper h2 a:before {
  background-color: #711C2F;
  transition: all 0.3s;
}
.wrapper h2 a:hover,
.wrapper h2 a:focus {
  color: #000;
}
.wrapper h2 a:hover:before,
.wrapper h2 a:focus:before {
  background-color: #000;
}
.section--red h2 {
  color: #fff;
}
.wrapper .section--red h2 a {
  color: #fff;
  transition: all 0.3s;
}
.wrapper .section--red h2 a:before {
  background-color: #fff;
  transition: all 0.3s;
}
.wrapper .section--red h2 a:hover,
.wrapper .section--red h2 a:focus {
  color: #D1E0B1;
}
.wrapper .section--red h2 a:hover:before,
.wrapper .section--red h2 a:focus:before {
  background-color: #D1E0B1;
}
h3 {
  font-weight: normal;
  font-size: 30px;
  line-height: 1.33333333;
}
h4 {
  font-weight: normal;
  font-family: 'MaisonNeueDemi', sans-serif;
}
.loud {
  font-size: 24px;
  line-height: 1.45833333;
}
.section--two .area {
  margin-top: 40px;
  margin-bottom: 40px;
  box-sizing: border-box;
  padding-right: 370px;
}
@media (max-width: 1024px) {
  .section--two .area {
    margin-top: 40px;
  }
}
@media (max-width: 767px) {
  .section--two .area {
    margin-top: 40px;
  }
}
@media (max-width: 1024px) {
  .section--two .area {
    margin-bottom: 40px;
  }
}
@media (max-width: 767px) {
  .section--two .area {
    margin-bottom: 40px;
  }
}
@media (max-width: 1024px) {
  .section--two .area {
    padding-right: 0;
  }
}
@media (max-width: 767px) {
  .section--two .area {
    margin-bottom: 100px;
  }
}
.section--four .area {
  margin-bottom: 80px;
}
@media (max-width: 1024px) {
  .section--four .area {
    margin-bottom: 80px;
  }
}
@media (max-width: 767px) {
  .section--four .area {
    margin-bottom: 80px;
  }
}
.section--multimood .area {
  position: absolute;
  right: 86px;
  bottom: 0;
  z-index: 2;
  width: auto;
  transform: translateY(50%);
}
#edit .section--multimood .area {
  min-height: 64px;
}
@media (max-width: 1519px) {
  .section--multimood .area {
    right: 60px;
  }
}
@media (max-width: 1024px) {
  .section--multimood .area {
    right: 40px;
  }
}
@media (max-width: 767px) {
  .section--multimood .area {
    right: 20px;
  }
}
.section--header .area {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 2;
  width: 174px;
  height: 174px;
  font-size: 18px;
  line-height: 1.11111111;
  font-weight: normal;
  font-family: 'MaisonNeueDemi', sans-serif;
  color: #711C2F;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/stoerer.svg);
  transform: translateY(50%) rotate(6deg);
}
@media (max-width: 1024px) {
  .section--header .area {
    width: 138px;
    height: 138px;
    font-size: 14px;
    line-height: 1.14285714;
  }
}
.section--one .area .pure.wide .body {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.section--one .area .flat {
  margin-top: 40px;
  margin-bottom: 40px;
}
@media (max-width: 1024px) {
  .section--one .area .flat {
    margin-top: 40px;
  }
}
@media (max-width: 767px) {
  .section--one .area .flat {
    margin-top: 40px;
  }
}
@media (max-width: 1024px) {
  .section--one .area .flat {
    margin-bottom: 40px;
  }
}
@media (max-width: 767px) {
  .section--one .area .flat {
    margin-bottom: 40px;
  }
}
.section--one .area .flat .body {
  margin-top: -12px;
  margin-bottom: -12px;
}
.section--one .area .flat .part {
  margin-top: 12px;
  margin-bottom: 12px;
}
.section--one .area .edge {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  flex-shrink: 0;
  margin-left: 0 !important;
  margin-right: 0 !important;
  max-width: 100vw;
}
.section--one .area .edge.wide {
  width: 100vw !important;
}
.section--one .area .edge.slim {
  width: 1052px !important;
}
.section--one .area .edge .pict .cb-image-caption {
  box-sizing: border-box;
  padding-left: 108px;
  padding-right: 108px;
}
@media (max-width: 1024px) {
  .section--one .area .edge .pict .cb-image-caption {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media (max-width: 767px) {
  .section--one .area .edge .pict .cb-image-caption {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.section--three .base .unit {
  margin-top: 16px;
  margin-bottom: 16px;
}
.section--three .base .unit .part {
  margin-top: 8px;
  margin-bottom: 8px;
}
.section--four .area .unit.slim {
  width: calc(100% - 646px);
  margin-left: 646px;
  margin-top: 0;
}
.section--four .area .unit.slim:last-child {
  margin-bottom: 0;
}
@media (max-width: 1519px) {
  .section--four .area .unit.slim {
    width: 65.26857984%;
    margin-left: 34.73142016%;
  }
}
@media (max-width: 1024px) {
  .section--four .area .unit.slim {
    margin-left: 0;
    width: 100%;
  }
}
.section--four .area .unit.slim .part {
  margin-top: 8px;
  margin-bottom: 8px;
}
.section--multimood .area .unit {
  margin: 0;
  width: auto;
}
.section--multimood .area .unit .part {
  margin: 0;
  width: auto;
  min-width: 50px;
}
@media (max-width: 767px) {
  .section--multimood .area .unit .part.link {
    max-width: calc(100vw - 40px);
  }
}
.section--multimood .area .unit .part.link a.open {
  float: left;
  font-size: 19px;
  line-height: 1.47368421;
  background-color: #711C2F;
  cursor: pointer;
  color: #fff;
  padding: 10px 46px 10px 14px;
  -webkit-appearance: none;
  border-radius: 0;
  border: none;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  background-size: 18px 18px;
  background-position: right 14px center;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-right-white.svg);
  transition: all 0.3s;
}
.section--multimood .area .unit .part.link a.open:hover,
.section--multimood .area .unit .part.link a.open:focus {
  color: #711C2F;
  background-color: #D1E0B1;
  background-image: url(/images/arrow-right-red.svg);
}
.section--multimood .area .unit .part.link a.open:before {
  display: none;
}
@media (min-width: 1025px) {
  .section--multimood .area .unit .part.link a.open {
    font-size: 24px;
    line-height: 1.33333333;
    padding: 16px 66px 16px 24px;
    background-position: right 24px center;
  }
}
.section--header .area .unit {
  margin: 0;
  width: 100%;
  height: 100%;
}
.section--header .area .unit .body {
  height: 100%;
}
.section--header .area .unit .part {
  width: 100%;
  height: 100%;
  margin: 0;
}
.section--header .area .unit .part.text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.section--header .area .unit .part.text * {
  height: 100%;
}
.section--header .area .unit .part.text a {
  float: left;
  width: 100%;
  height: 100%;
  display: block;
  border: none;
  color: #711C2F;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.section--header .area .unit .part.text a:hover,
.section--header .area .unit .part.text a:focus {
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/stoerer-hover.svg);
}
.section--header .area .unit .part.text p.norm {
  display: flex;
  justify-content: center;
  align-items: center;
}
.unit.form .body {
  display: flex;
  flex-direction: column;
}
.unit.form .part {
  order: 3;
}
.unit.form .part:first-child {
  order: 1;
}
.unit.form .part.must {
  order: 2;
}
.unit.form.edge {
  background-size: 403px auto;
  background-position: 100% 192px;
  background-repeat: no-repeat;
  background-image: url(/images/form-map-2022-04-28.svg);
}
@media (max-width: 1024px) {
  .unit.form.edge {
    background-size: 67% auto;
  }
}
@media (max-width: 767px) {
  .unit.form.edge {
    background-position: 100% 208px;
  }
}
.ie11 .unit.form.edge {
  background-size: 403px 256px;
}
@media (max-width: 767px) {
  .ie11 .unit.form.edge {
    background-size: 220px 140px;
  }
}
div.link a.open {
  font-size: 20px;
  line-height: 1.5;
  color: #928D8A;
  border-color: #928D8A;
}
div.link a.open:hover,
div.link a.open:focus {
  color: #711C2F;
  border-color: #711C2F;
}
div.link a.open:before {
  display: none;
}
.section--two .area div.link a.open {
  float: left;
  font-size: 19px;
  line-height: 1.47368421;
  background-color: #711C2F;
  cursor: pointer;
  color: #fff;
  padding: 10px 46px 10px 14px;
  -webkit-appearance: none;
  border-radius: 0;
  border: none;
  text-align: center;
  text-decoration: none;
  background-size: 18px 18px;
  background-position: right 14px center;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-right-white.svg);
  transition: all 0.3s;
  box-sizing: border-box;
  padding-top: 9px;
  padding-bottom: 9px;
  border: 1px solid #fff;
}
.section--two .area div.link a.open:hover,
.section--two .area div.link a.open:focus {
  color: #711C2F;
  background-color: #D1E0B1;
  background-image: url(/images/arrow-right-red.svg);
}
.section--two .area div.link a.open:before {
  display: none;
}
.section--two .area div.link a.open:hover,
.section--two .area div.link a.open:focus {
  border-color: #D1E0B1;
}
div.load a.load {
  font-size: 20px;
  line-height: 1.5;
  color: #928D8A;
  border-color: #928D8A;
}
div.load a.load:hover,
div.load a.load:focus {
  color: #711C2F;
  border-color: #711C2F;
}
div.load a.load:before {
  display: none;
}
@media (max-width: 1024px) {
  body.cb-toggle-target-active {
    overflow: hidden;
  }
  .navi {
    display: none;
  }
}
a.togglenavigation {
  position: absolute;
  top: 24px;
  right: 40px;
  z-index: 2001;
  width: 24px;
  height: 12px;
  cursor: pointer;
}
@media (max-width: 767px) {
  a.togglenavigation {
    right: 20px;
  }
}
a.togglenavigation:before {
  display: none;
}
.tline {
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 2px;
  background-color: #000;
  border-radius: 2px;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.tline--2,
.tline--3 {
  top: 50%;
  transform: translateY(-50%);
}
.tline--4 {
  top: unset;
  bottom: 0;
}
body.cb-toggle-target-active .tline {
  background-color: #D1E0B1;
}
body.cb-toggle-target-active .tline--1 {
  opacity: 0;
  transform: translateY(-10px);
}
body.cb-toggle-target-active .tline--2 {
  transform: translateY(-50%) rotate(-45deg);
}
body.cb-toggle-target-active .tline--3 {
  transform: translateY(-50%) rotate(45deg);
}
body.cb-toggle-target-active .tline--4 {
  opacity: 0;
  transform: translateY(10px);
}
.mobile-navigation {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  width: 100%;
  height: 100%;
  background-color: #711C2F;
  overflow-y: scroll;
  transform: translateY(-100%);
  transition: all 0.6s cubic-bezier(0.05, 0.8, 0.5, 1);
}
body.cb-toggle-target-active .mobile-navigation {
  transform: translateY(0);
}
.mobile-navi-animation {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding: 0 40px;
  opacity: 0;
  transform: translateY(-100vh);
  transition: all 0s 0.6s cubic-bezier(0.05, 0.8, 0.5, 1);
}
@media (max-width: 767px) {
  .mobile-navi-animation {
    padding: 0 20px;
  }
}
body.cb-toggle-target-active .mobile-navi-animation {
  transition: all 1s cubic-bezier(0.05, 0.8, 0.5, 1);
  transform: translateY(0);
  opacity: 1;
}
.mobilehomelink {
  float: left;
  margin-top: 24px;
  height: 84px;
}
.mobilehomelink:before {
  display: none;
}
.mobile-navigation div.navi {
  float: left;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 348px);
  margin: 70px 0 170px;
}
.mobile-navigation div.navi > .cb-toggle {
  display: none;
}
.mobile-navigation div.navi > .item {
  float: left;
  position: relative;
  margin-top: 60px;
}
@media (max-width: 767px) {
  .mobile-navigation div.navi > .item {
    margin-top: 42px;
  }
}
.mobile-navigation div.navi > .item.init {
  margin-top: 0;
}
.mobile-navigation div.navi > .item.item-empty > .cb-toggle {
  display: none;
}
.mobile-navigation div.navi > .item > .menu {
  display: block;
  color: #D1E0B1;
  position: relative;
  font-size: 30px;
  line-height: 1.33333333;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
}
@media (max-width: 767px) {
  .mobile-navigation div.navi > .item > .menu {
    font-size: 20px;
    line-height: 1.5;
  }
}
.mobile-navigation div.navi > .item > .menu:before {
  display: none;
  background-color: #D1E0B1;
}
.mobile-navigation div.navi > .item > .menu.path:before {
  display: block;
}
.unit.realestate {
  margin-top: 0;
}
.unit.realestate .part {
  margin-top: 0;
}
.realestate-item-group {
  float: left;
  width: 100%;
}
.realestate {
  float: left;
  width: 100%;
  color: #000;
  font-size: 15px;
  line-height: 1.33333333;
  min-height: 676px;
}
#edit .realestate {
  min-height: 100px;
}
.realestate__section {
  float: left;
  width: calc(100% - 646px);
  margin-left: 646px;
  margin-top: 50px;
}
@media (max-width: 1519px) {
  .realestate__section {
    width: 65.26857984%;
    margin-left: 34.73142016%;
  }
}
@media (max-width: 1024px) {
  .realestate__section {
    margin-left: 0;
    width: 100%;
  }
}
.realestate__section--table {
  overflow-x: auto;
}
.realestate__section--actions--top,
.realestate__section--info--top {
  display: none;
}
.realestate__section--filter {
  margin-top: 40px;
}
@media (max-width: 1024px) {
  .realestate__section--filter {
    margin-top: 40px;
  }
}
@media (max-width: 767px) {
  .realestate__section--filter {
    margin-top: 40px;
  }
}
.realestate__section--actions {
  margin-top: 20px;
}
@media (max-width: 767px) {
  .realestate__section--actions {
    margin-top: 16px;
  }
}
.realestate svg {
  float: left;
  width: 100%;
  overflow: visible;
}
.appartment {
  cursor: pointer;
}
.appartment * {
  fill: #fff;
  transition: all 0.4s;
}
.appartment.is-disabled * {
  fill: #efefef !important;
  pointer-events: none;
  transition: none !important;
}
.appartment--type1.is-hovered *,
.appartment--type1.is-active * {
  fill: #A0D2BE;
}
.appartment--type2.is-hovered *,
.appartment--type2.is-active * {
  fill: #BFD8B9;
}
.appartment--type3.is-hovered *,
.appartment--type3.is-active * {
  fill: #D1E0B1;
}
.appartment--type4.is-hovered *,
.appartment--type4.is-active * {
  fill: #D7E6A0;
}
.filter-section {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
@media (max-width: 1519px) {
  .filter-section {
    width: 100%;
    margin-right: 0;
    margin-top: 20px;
  }
  .filter-section:first-child {
    margin-top: 0;
  }
}
.filter-section--selectors {
  position: relative;
  z-index: 2;
  margin-top: -20px;
}
.filter {
  float: left;
  width: calc((100% - (16px + 1px)) / 2);
  margin-left: 16px;
  margin-top: 20px;
}
.filter:first-child {
  margin-left: 0;
}
@media (min-width: 1520px) {
  .filter {
    width: calc((100% - (16px * 2)) / 3);
  }
}
.filter.is-disabled {
  opacity: 0.4;
  pointer-events: none;
}
@media only screen and (max-width: 767px) {
  .filter {
    width: 100%;
    margin-right: 0;
    margin-top: 16px;
    margin-left: 0;
  }
  .filter:first-child {
    margin-top: 0;
  }
}
.realestate .o-label {
  display: block;
  padding-bottom: 10px;
}
.realestate .o-form-group {
  float: left;
  width: 100%;
}
.realestate .o-form-input {
  float: left;
  width: 100%;
}
.realestate .o-dropdown {
  float: left;
  width: 100%;
  position: relative;
  border: 1px solid #000;
  box-sizing: border-box;
}
.realestate .o-dropdown__selected {
  float: left;
  width: 100%;
  position: relative;
}
.realestate .o-dropdown__link {
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
  height: 100%;
  background-image: url(/images/corner-down-black.svg);
  background-size: 10px 5px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  z-index: 2;
}
.realestate .o-dropdown__link:hover {
  cursor: pointer;
}
.realestate .o-dropdown.is-open .o-dropdown__link {
  background-image: url(/images/corner-up-black.svg);
}
.realestate .o-dropdown__results {
  position: absolute;
  top: 100%;
  left: -1px;
  width: calc(100% + 2px);
  background-color: #fff;
  z-index: 3;
  display: none;
  border: 1px solid #000;
  box-sizing: border-box;
}
.realestate .o-dropdown.is-open .o-dropdown__results {
  display: block;
}
.realestate .o-dropdown__item {
  padding: 10px;
  min-height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
}
.realestate .o-dropdown__item:hover {
  cursor: pointer;
  background-color: #e5e3e1;
}
.realestate .o-dropdown__selected .o-dropdown__item {
  padding-right: 50px;
}
.realestate .o-dropdown__item.is-active {
  font-weight: normal;
  font-family: 'MaisonNeueDemi', sans-serif;
  background-color: #e5e3e1;
}
.o-slider {
  float: left;
  width: 100%;
}
.o-slider__bar {
  float: left;
  width: 100%;
  margin: 10px 0;
  position: relative;
  height: 1px;
  background-color: #000;
}
.o-slider-handler {
  position: absolute;
  top: -10px;
  width: 21px;
  height: 21px;
  background-color: #000;
  border-radius: 21px;
}
.o-slider-handler:hover {
  cursor: pointer;
}
.o-slider-handler--min {
  left: 0;
}
.o-slider-handler--max {
  left: 100%;
}
.o-slider__values {
  float: left;
  width: 100%;
  margin-top: 4px;
  font-size: 13px;
  line-height: 1.38461538;
}
.o-slider-value--min {
  float: left;
}
.o-slider-value--max {
  float: right;
}
.realestate-button {
  float: left;
  font-size: 19px;
  line-height: 1.47368421;
  background-color: #711C2F;
  cursor: pointer;
  padding: 10px 46px 10px 14px;
  -webkit-appearance: none;
  border-radius: 0;
  border: none;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  background-size: 18px 18px;
  background-position: right 14px center;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-right-white.svg);
  transition: all 0.3s;
  background-color: #928D8A;
  color: #fff;
  font-size: 16px;
  line-height: 1.5;
  padding: 8px 16px;
  background-image: none !important;
  margin: 0 16px;
}
.realestate-button:hover,
.realestate-button:focus {
  color: #711C2F;
  background-color: #D1E0B1;
  background-image: url(/images/arrow-right-red.svg);
}
.realestate-button:before {
  display: none;
}
.realestate-button:hover,
.realestate-button:focus {
  background-color: #711C2F;
  color: #fff;
}
.realestate-button:first-child {
  margin-left: 0;
}
.realestate-button:last-child {
  margin-right: 0;
}
.realestate-button.is-disabled {
  pointer-events: none;
  background-color: #e5e3e1;
}
@media only screen and (max-width: 767px) {
  .realestate-button {
    width: 100%;
  }
}
.table--realestate {
  float: left;
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}
@media (max-width: 1179px) {
  .table--realestate {
    font-size: 14px;
  }
}
.table--realestate tr.table-appartment {
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  background-color: transparent;
  cursor: pointer;
  display: none;
}
.table--realestate tr.table-appartment.is-visible {
  display: table-row;
}
.table--realestate tr.table-appartment.is-hovered,
.table--realestate tr.table-appartment.is-active {
  cursor: pointer;
}
.table--realestate tr.table-appartment.status--cbPluginREStatusBought {
  display: none;
}
.table--realestate tr.table-appartment.type--1.is-hovered,
.table--realestate tr.table-appartment.type--1.is-active {
  background-color: #A0D2BE;
}
.table--realestate tr.table-appartment.type--2.is-hovered,
.table--realestate tr.table-appartment.type--2.is-active {
  background-color: #BFD8B9;
}
.table--realestate tr.table-appartment.type--3.is-hovered,
.table--realestate tr.table-appartment.type--3.is-active {
  background-color: #D1E0B1;
}
.table--realestate tr.table-appartment.type--4.is-hovered,
.table--realestate tr.table-appartment.type--4.is-active {
  background-color: #D7E6A0;
}
#root .table--realestate tr.is-disabled {
  background-color: transparent !important;
  color: rgba(0, 0, 0, 0.4) !important;
  cursor: default !important;
}
@media only screen and (max-width: 767px) {
  #root .table--realestate tr.is-disabled {
    background-color: rgba(229, 227, 225, 0.2) !important;
  }
}
#root .table--realestate tr.is-disabled td {
  border-color: rgba(0, 0, 0, 0.2) !important;
}
#root .table--realestate tr.is-disabled .table-anchor--contact {
  display: none;
}
#root .table--realestate tr.is-disabled a.realestate-download {
  color: rgba(0, 0, 0, 0.4) !important;
}
#root .table--realestate tr.is-disabled a.realestate-download:hover,
#root .table--realestate tr.is-disabled a.realestate-download:focus {
  color: #000 !important;
}
.table--realestate thead tr {
  border-bottom: 1px solid #000;
}
.table-group th {
  padding-top: 36px !important;
  font-weight: normal;
  font-family: 'MaisonNeueDemi', sans-serif;
  font-size: 16px;
  line-height: 1.625;
}
.table--realestate th {
  font-weight: normal;
  font-family: 'MaisonNeueDemi', sans-serif;
  padding: 4px 10px 2px;
  vertical-align: bottom;
  -webkit-font-smoothing: antialiased;
  padding-bottom: 12px;
  text-align: left;
}
.table--realestate th:first-child {
  padding-left: 0;
}
.table--realestate th:last-child {
  padding-right: 0;
}
.table--realestate td {
  height: 40px;
  padding: 4px 10px 2px;
  vertical-align: middle;
  white-space: nowrap;
}
.table--realestate td:first-child {
  padding-left: 0;
}
.table--realestate td:last-child {
  padding-right: 0;
}
.table-column--4,
.table-column--5,
.table-column--6,
.table-column--7 {
  text-align: right !important;
}
th.table-column--8 {
  text-align: right;
}
td.table-column--8 {
  display: flex;
  height: 46px;
  padding-top: 0;
  padding-bottom: 0;
  justify-content: flex-end;
  align-items: center;
}
.table-anchor {
  display: inline-block;
}
.table-anchor:before {
  display: none;
}
.table-anchor--contact {
  margin-right: 20px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  width: 28px;
  height: 46px;
  border: none !important;
  background-size: 28px 18px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/icon-contact-black.svg);
}
.table-anchor--contact:hover {
  background-image: url(/images/icon-contact-red.svg) !important;
}
@media only screen and (max-width: 767px) {
  .table-anchor--contact {
    height: 18px;
    background-image: url(/images/icon-contact-black.svg) !important;
  }
}
#root .realestate-download {
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  color: #000;
  border: none !important;
}
#root .realestate-download:after {
  content: '↓';
}
#root .realestate-download:hover,
#root .realestate-download:focus {
  color: #711C2F;
}
tr.status--cbPluginREStatusReserved .realestate-download,
tr.status--cbPluginREStatusBought .realestate-download {
  display: none;
}
.table--realestate .heading--mobile {
  display: none;
}
@media only screen and (max-width: 767px) {
  .table--realestate thead {
    display: none;
  }
  .table--realestate tr.table-appartment {
    padding: 8px 16px 16px;
    color: #000 !important;
    border: none;
    margin-top: 16px;
    background-color: #e5e3e1 !important;
    box-sizing: border-box;
  }
  .table--realestate tr.table-appartment.is-visible {
    display: block !important;
  }
  .table--realestate tr.table-appartment:first-child {
    margin-top: 0;
  }
  .table--realestate tr.table-appartment:last-child td {
    padding-top: 0;
    padding-bottom: 0;
  }
  .table--realestate td {
    display: block;
    text-align: right !important;
    height: auto;
    padding: 11px 0 9px !important;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    border-bottom: 1px solid #000;
  }
  .table--realestate td.table-column--1 {
    color: #711C2F;
    font-weight: normal;
    font-family: 'MaisonNeueDemi', sans-serif;
    -webkit-font-smoothing: antialiased;
  }
  .table--realestate .heading--mobile {
    float: left;
    display: block;
    flex-grow: 1;
    width: 50%;
    text-align: left !important;
    font-weight: normal;
    font-family: 'MaisonNeueDemi', sans-serif;
    -webkit-font-smoothing: antialiased;
  }
}
.realestate-information {
  float: left;
  width: 100%;
}
.information-text {
  float: left;
  width: 100%;
}
.realestate .cb-navi-sticky {
  float: left;
  width: 100%;
}
.realestate .cb-navi-sticky.cb-navi-sticky-clone {
  display: none !important;
}
.realestate .cb-navi-sticky.cb-navi-fixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
}
@media (max-width: 1024px) {
  .realestate .cb-navi-sticky.cb-navi-fixed {
    position: relative;
  }
}
.realestate-desk {
  float: left;
  width: 100%;
  position: relative;
  box-sizing: border-box;
}
.cb-navi-fixed .realestate-desk {
  width: calc(100% - 172px);
  margin: 0 86px;
}
@media (max-width: 1519px) {
  .cb-navi-fixed .realestate-desk {
    width: calc(100% - 120px);
    margin: 0 60px;
  }
}
@media (max-width: 1024px) {
  .cb-navi-fixed .realestate-desk {
    width: 100%;
    margin: 0;
  }
}
.realestate__section--svg {
  position: absolute;
  left: 0;
  top: 100px;
  z-index: 2;
  width: 590px;
  margin: 0;
}
.realestate-item-group--2 .realestate__section--svg {
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/background-flugbrunnenareal.svg);
}
.ie11 .realestate__section--svg {
  height: 442px;
}
@media (max-width: 1519px) {
  .realestate__section--svg {
    width: 31.78807947%;
  }
}
@media (max-width: 1024px) {
  .realestate__section--svg {
    position: static;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
  }
  .realestate__section--svg svg {
    width: 590px;
    max-width: 100%;
  }
}
@media (max-width: 1024px) and (max-width: 1024px) {
  .realestate__section--svg {
    margin-top: 40px;
  }
}
@media (max-width: 1024px) and (max-width: 767px) {
  .realestate__section--svg {
    margin-top: 40px;
  }
}
.realestate__section--svg svg {
  background-size: 100% auto;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-image: url(/images/isometrie-flugbrunnenareal-background.svg);
}
.realestate-item-group--2 .realestate__section--svg svg {
  background: none;
}
.flagWrapper {
  display: none;
}
.floor.is-active .flagWrapper {
  display: block;
}
.flagBG {
  fill: #711C2F;
}
.flagText path {
  fill: #fff;
}
.triangle {
  fill: #141412;
}
.line {
  fill: none;
  stroke: #0E0E09;
  stroke-width: 0.5;
  stroke-miterlimit: 10;
}
.realestate--manage {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  font-size: 14px;
  line-height: 1.2;
  font-family: 'MaisonNeueLight', sans-serif;
  color: #333;
}
.realestate--manage input,
.realestate--manage textarea,
.realestate--manage select {
  display: block;
  border: 1px solid #000;
  border-radius: 2px;
  padding: 3px;
  background-color: #fff;
}
.realestate--manage * {
  font-size: 14px;
  line-height: 1.2;
  font-family: helvetica;
  color: #333;
}
.realestate--manage a:hover {
  color: #555;
}
.realestate--manage .hidden {
  display: none !important;
}
.manage-section {
  float: left;
  width: 100%;
  margin-top: 30px;
}
.realestate-configuration .realestate-form-group--file {
  width: 100%;
  margin-top: 10px;
}
.realestate-configuration .realestate-form-group--file .realestate-form-input {
  margin-left: 0;
}
.realestate-heading {
  float: left;
  width: 100%;
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: normal;
  font-family: 'MaisonNeueDemi', sans-serif;
}
.realestate-form-group {
  float: left;
  margin-right: 40px;
}
.realestate-form-group:last-child {
  margin-left: 0;
}
.realestate-label,
.realestate-form-label {
  float: left;
  min-height: 32px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
.realestate-form-input {
  float: left;
  margin-left: 10px;
  min-height: 32px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
.manage-button {
  float: left;
  display: block;
  background-color: #333;
  color: #fff;
  line-height: 32px;
  border-radius: 4px;
  padding: 0 10px;
  font-weight: 300;
}
.manage-button:hover {
  background-color: #444;
  cursor: pointer;
}
.manage-button--delete,
.manage-button--download,
.manage-button--upload,
.manage-button--save,
.manage-button--edit,
.manage-button--abort {
  width: 32px;
  height: 32px;
  padding: 0;
  background-repeat: no-repeat;
  background-size: 16px 16px;
  background-position: 50% 50%;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
}
.manage-button--delete {
  background-image: url(/icons/realestate/realestate-trash-white.svg);
}
.manage-button--download {
  background-image: url(/icons/realestate/realestate-download-white.svg);
}
.manage-button--upload {
  background-image: url(/icons/realestate/realestate-upload-white.svg);
}
.manage-button--save {
  background-image: url(/icons/realestate/realestate-save-white.svg);
}
.manage-button--edit {
  background-image: url(/icons/realestate/realestate-edit-white.svg);
}
.manage-button--abort {
  background-image: url(/icons/realestate/realestate-abort-white.svg);
}
.manage-section--report table {
  width: 100%;
  margin-bottom: 40px;
  border-collapse: collapse;
}
.manage-section--report table tr {
  min-height: 36px;
}
.manage-section--report table tr.odd {
  background: #eee;
}
.manage-section--report table th,
.manage-section--report table td {
  text-align: left;
  vertical-align: middle;
  padding: 5px 10px;
  white-space: nowrap;
}
.manage-section--report table th .button-width,
.manage-section--report table td .button-width {
  float: left;
  min-width: 156px;
}
.manage-section--report table th .manage-button,
.manage-section--report table td .manage-button {
  margin: 5px;
}
.manage-section--report table th .manage-button:first-child,
.manage-section--report table td .manage-button:first-child {
  margin-left: 0;
}
.manage-section--report table th .manage-button:last-child,
.manage-section--report table td .manage-button:last-child {
  margin-right: 0;
}
.manage-section--report table th {
  padding: 20px 10px;
  background: #333;
  color: #fff;
}
.manage-section--report table th a {
  color: #fff;
  text-decoration: none;
}
.manage-section--report table th a:hover,
.manage-section--report table th a:focus {
  color: #ccc;
}
.manage-section--report table .report-sort--desc,
.manage-section--report table .report-sort--asc {
  padding-right: 20px;
  background-image: url(/icons/realestate/realestate-sort-white.svg);
  background-size: 10px 10px;
  background-position: 100% 50%;
  background-repeat: no-repeat;
}
.realestate-configuration {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding: 60px;
  font-family: helvetica, sans-serif;
  font-size: 14px;
  line-height: 1.2;
}
.realestate-configuration .realestate-configuration,
.realestate-configuration.realestate-configuration--data {
  padding: 0;
}
.realestate-configuration h1 {
  float: left;
  width: 100%;
  font-weight: normal;
  font-family: 'MaisonNeueDemi', sans-serif;
}
.realestate-configuration h3 {
  float: left;
  width: 100%;
  margin-top: 50px;
}
.realestate-tab-container {
  float: left;
  width: 100%;
  margin-top: 20px;
}
.realestate-content {
  float: left;
  width: 100%;
  margin-top: 20px;
}
.realestate-configuration .realestate--manage {
  box-sizing: border-box;
  padding: 0 40px;
}
.realestate-configuration .realestate--manage:nth-child(2n-1) {
  background-color: #e3f4fd;
}
.realestate-configuration--global {
  padding: 0;
  border-top: 1px solid #ccc;
}
.re-config-content {
  float: left;
  width: 100%;
}
.re-config-content .realestate-form-group {
  float: left;
  width: auto;
}
.re-config-content input {
  float: left;
}
.realestate-definition {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
}
.realestate-definition:nth-child(even) {
  background: #eee;
}
.realestate-definition .realestate-definition-actions {
  float: left;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
}
.realestate-definition h4 {
  font-size: 16px;
  display: block;
  float: left;
  width: 100%;
  margin-bottom: 10px;
  margin-top: 10px;
}
.realestate-definition a {
  text-decoration: underline;
  margin-right: 10px;
}
.definition label {
  width: 100%;
  display: block;
  margin-top: 20px;
}
.definition textarea {
  width: 50%;
  min-height: 200px;
  margin-bottom: 20px;
}
.realestate-definition-add {
  text-decoration: underline;
}
#disp.mail--realestate-contact {
  width: 1266px !important;
}
#disp.mail--realestate-contact .disp-wrapper {
  padding: 100px;
}
@media (max-width: 1024px) {
  #disp.mail--realestate-contact .disp-wrapper {
    padding: 80px;
  }
}
@media (max-width: 767px) {
  #disp.mail--realestate-contact .disp-wrapper {
    padding: 30px;
  }
}
#disp.mail--realestate-contact .head .ctrl a {
  position: absolute;
  right: -60px;
  top: -60px;
  z-index: 2;
}
@media (max-width: 767px) {
  #disp.mail--realestate-contact .head .ctrl a {
    width: 20px;
    height: 20px;
    right: 0;
    top: 0;
  }
}
#disp.mail--realestate-contact .body {
  margin-top: 0;
  margin-bottom: 30px;
}
#disp.mail--realestate-contact .foot {
  float: right;
  width: 47.8424015%;
}
#disp.mail--realestate-contact .foot a {
  float: right;
  width: 71.76470588%;
  background: none;
  padding: 0;
  color: #5abb55;
  text-decoration: none;
}
#disp.mail--realestate-contact .foot a:before {
  display: none;
}
#disp .realestate-contact {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 19px;
  line-height: 1.47368421;
}
#disp .realestate-contact__title {
  float: left;
  width: 100%;
  margin-bottom: 38px;
}
#disp .realestate-contact__title h3 {
  font-size: 19px;
  line-height: 1.47368421;
  font-weight: normal;
  font-family: 'MaisonNeueLight', sans-serif;
  color: #000;
}
.realestate-contact h4 {
  font-weight: normal;
  font-family: 'MaisonNeueDemi', sans-serif;
  -webkit-font-smoothing: antialiased;
}
.realestate-contact__address {
  float: left;
  width: 100%;
  margin-bottom: 24px;
}
.realestate-contact__information {
  float: left;
  width: 37.89868668%;
}
@media (max-width: 1024px) {
  .realestate-contact__information {
    width: 100%;
  }
}
.realestate-contact__appartment-data {
  float: left;
  width: 100%;
}
@media (max-width: 767px) {
  .realestate-contact__appartment-data {
    font-size: 15px;
    line-height: 1.33333333;
  }
}
.appartment-data {
  float: left;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  margin-bottom: -1px;
}
.appartment-data:last-child {
  margin-bottom: 0;
}
.appartment-data__title {
  font-weight: normal;
  font-family: 'MaisonNeueDemi', sans-serif;
  -webkit-font-smoothing: antialiased;
  padding: 9px 0;
}
.appartment-data__value {
  padding: 9px 0;
}
.appartment-data .table-anchor--contact {
  display: none;
}
.realestate-contact__notice {
  float: left;
  width: 100%;
  padding-bottom: 318px;
  background-size: auto 274px;
  background-position: 0 100%;
  background-repeat: no-repeat;
  font-size: 0;
  line-height: 0;
}
@media (max-width: 767px) {
  .realestate-contact__notice {
    background-size: auto 244px;
  }
}
.B1-F1-A1-T4 .realestate-contact__notice {
  background-image: url(/images/isometrie/2024-10-21/B1-F1-A1.svg);
}
.B1-F1-A2-T2 .realestate-contact__notice {
  background-image: url(/images/isometrie/2024-10-21/B1-F1-A2.svg);
}
.B1-F2-A1-T2 .realestate-contact__notice {
  background-image: url(/images/isometrie/2024-10-21/B1-F2-A1.svg);
}
.B1-F2-A2-T3 .realestate-contact__notice {
  background-image: url(/images/isometrie/2024-10-21/B1-F2-A2.svg);
}
.B1-F3-A1-T2 .realestate-contact__notice {
  background-image: url(/images/isometrie/2024-10-21/B1-F3-A1.svg);
}
.B1-F3-A2-T3 .realestate-contact__notice {
  background-image: url(/images/isometrie/2024-10-21/B1-F3-A2.svg);
}
.B1-F4-A1-T2 .realestate-contact__notice {
  background-image: url(/images/isometrie/2024-10-21/B1-F4-A1.svg);
}
.B1-F4-A2-T3 .realestate-contact__notice {
  background-image: url(/images/isometrie/2024-10-21/B1-F4-A2.svg);
}
.B1-F5-A1-T3 .realestate-contact__notice {
  background-image: url(/images/isometrie/2024-10-21/B1-F5-A1.svg);
}
.B2-F1-A1-T3 .realestate-contact__notice {
  background-image: url(/images/isometrie/2024-10-21/B2-F1-A1.svg);
}
.B2-F2-A1-T2 .realestate-contact__notice {
  background-image: url(/images/isometrie/2024-10-21/B2-F2-A1.svg);
}
.B2-F2-A2-T1 .realestate-contact__notice {
  background-image: url(/images/isometrie/2024-10-21/B2-F2-A2.svg);
}
.B2-F3-A1-T2 .realestate-contact__notice {
  background-image: url(/images/isometrie/2024-10-21/B2-F3-A1.svg);
}
.B2-F3-A2-T1 .realestate-contact__notice {
  background-image: url(/images/isometrie/2024-10-21/B2-F3-A2.svg);
}
.B2-F4-A1-T2 .realestate-contact__notice {
  background-image: url(/images/isometrie/2024-10-21/B2-F4-A1.svg);
}
.B2-F4-A2-T1 .realestate-contact__notice {
  background-image: url(/images/isometrie/2024-10-21/B2-F4-A2.svg);
}
.B2-F5-A1-T2 .realestate-contact__notice {
  background-image: url(/images/isometrie/2024-10-21/B2-F5-A1.svg);
}
.realestate-contact__appartment-form {
  float: left;
  width: 47.8424015%;
}
@media (max-width: 1024px) {
  .realestate-contact__appartment-form {
    width: 100%;
    margin-top: 50px;
  }
}
.realestate-contact__appartment-form .cb-form-required {
  float: left;
  width: 100%;
  font-size: 16px;
  line-height: 1.5625;
  color: #928D8A;
}
.realestate-contact__appartment-form .realestate-form-group {
  margin-right: 0;
  width: 100%;
  margin-top: 16px;
}
.realestate-contact__appartment-form .realestate-form-label {
  float: left;
  min-height: 50px;
  width: 25.49019608%;
  font-size: 16px;
  line-height: 1.625;
}
@media (max-width: 767px) {
  .realestate-contact__appartment-form .realestate-form-label {
    font-size: 17px;
    line-height: 1.47058824;
  }
}
@media only screen and (max-width: 767px) {
  .realestate-contact__appartment-form .realestate-form-label {
    width: 100%;
    min-height: 0;
    padding-bottom: 5px;
  }
}
.realestate-contact__appartment-form .realestate-form-input {
  float: right;
  margin-left: 0;
  width: 71.76470588%;
}
@media only screen and (max-width: 767px) {
  .realestate-contact__appartment-form .realestate-form-input {
    width: 100%;
  }
}
#root .realestate-contact__appartment-form .realestate-form-input input[type*='text'],
#root .realestate-contact__appartment-form .realestate-form-input textarea {
  float: left;
  width: 100%;
  background: transparent;
  min-height: 50px;
  padding: 11px 10px;
  box-sizing: border-box;
  border: 1px solid #000;
  box-shadow: none;
  -webkit-appearance: none;
}
.realestate-contact__appartment-form .checkbox.realestate-form-group {
  width: 71.76470588%;
  margin-left: 27.45098039%;
  position: relative;
  box-sizing: border-box;
  padding-left: 34px;
}
@media only screen and (max-width: 767px) {
  .realestate-contact__appartment-form .checkbox.realestate-form-group {
    width: 100%;
    margin-left: 0;
  }
}
.realestate-contact__appartment-form .checkbox .realestate-form-label {
  width: 100%;
  margin-top: 0;
}
.realestate-contact__appartment-form .checkbox .realestate-form-label a {
  display: inline-block;
  margin-top: 10px;
  color: #928D8A;
  position: relative;
  text-decoration: none;
}
.realestate-contact__appartment-form .checkbox .realestate-form-label a:before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: #928D8A;
}
.realestate-contact__appartment-form .checkbox .realestate-form-label a:hover:before,
.realestate-contact__appartment-form .checkbox .realestate-form-label a:focus:before {
  background-color: #711C2F;
}
.realestate-contact__appartment-form .checkbox .realestate-form-input {
  width: auto;
  position: absolute;
  left: 0;
  top: 0;
}
.realestate-contact__appartment-form .realestate-form-group--dropdown {
  width: 71.76470588%;
  margin-left: 28.43137255%;
  position: relative;
  box-sizing: border-box;
  margin-top: 40px;
}
@media only screen and (max-width: 767px) {
  .realestate-contact__appartment-form .realestate-form-group--dropdown {
    width: 100%;
    margin-left: 0;
  }
}
.realestate-contact__appartment-form .realestate-form-group--dropdown .realestate-form-label {
  width: 100%;
  margin-top: 0;
  min-height: 0;
  margin-bottom: 4px;
}
.realestate-form-dropdown {
  float: left;
  width: 100%;
  position: relative;
  border: 1px solid #000;
  box-sizing: border-box;
}
.realestate-form-dropdown__selected {
  float: left;
  width: 100%;
  position: relative;
}
.realestate-form-dropdown__link {
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
  height: 100%;
  background-image: url(/images/corner-down-black.svg);
  background-size: 12px 6px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  z-index: 2;
}
.realestate-form-dropdown__link:hover {
  cursor: pointer;
}
.realestate-form-dropdown.is-open .realestate-form-dropdown__link {
  transform: rotate(180deg);
}
.realestate-form-dropdown__results {
  position: absolute;
  top: 100%;
  left: -1px;
  width: calc(100% + 2px);
  background-color: #FFF;
  border: 1px solid #000;
  box-sizing: border-box;
  z-index: 3;
  display: none;
}
.realestate-form-dropdown.is-open .realestate-form-dropdown__results {
  display: block;
}
.realestate-form-dropdown__item {
  padding: 5px 10px;
  background: transparent;
  min-height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
}
.realestate-form-dropdown__item:hover {
  cursor: pointer;
  background-color: #e5e3e1;
}
.realestate-form-dropdown__selected .realestate-form-dropdown__item {
  padding-right: 50px;
}
.realestate-form-dropdown__item.is-active {
  background-color: #e5e3e1;
  font-weight: normal;
  font-family: 'MaisonNeueDemi', sans-serif;
}
@media (max-width: 767px) {
  .wrapper {
    font-size: 17px;
  }
  h1 {
    font-size: 26px;
  }
  h2 {
    font-size: 22px;
  }
  h3 {
    font-size: 22px;
  }
  .loud {
    font-size: 19px;
  }
}
#head {
  margin-bottom: -8px;
}
.section--red {
  margin-top: 40px;
}
.section--three .base {
  margin-top: 16px;
  margin-bottom: 16px;
}
.section--four .area .unit.slim {
  margin-bottom: 32px;
}
.cb-intro {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5000;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  transition: height 0s 1.05s;
  display: none;
}
.cb-intro-show .cb-intro {
  display: block;
}
.cb-intro-hide .cb-intro {
  pointer-events: none;
  height: 0;
}
.cb-intro__wrapper {
  float: left;
  width: 100%;
  min-height: 100%;
  position: relative;
}
.cb-intro__stripes {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
}
.cb-intro__stripe {
  float: left;
  width: 16.66666667%;
  height: 100%;
  background-color: #711C2F;
  transition: all 0.8s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
.cb-intro-hide .cb-intro__stripe {
  height: 0;
}
.cb-intro__stripe--1 {
  transition: all 0.8s 0.25s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
.cb-intro__stripe--2 {
  transition: all 0.8s 0.2s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
.cb-intro__stripe--3 {
  transition: all 0.8s 0.15s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
.cb-intro__stripe--4 {
  transition: all 0.8s 0.1s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
.cb-intro__stripe--5 {
  transition: all 0.8s 0.05s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
.cb-intro__content {
  float: left;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 3;
  transition: all 0.6s;
}
.cb-intro-hide .cb-intro__content {
  opacity: 0;
}
.introfrutiger {
  position: absolute;
  right: 86px;
  top: 86px;
  width: 92px;
  z-index: 2;
}
@media (max-width: 1024px) {
  .introfrutiger {
    width: 68px;
    right: 40px;
    top: 40px;
  }
}
@media (max-width: 767px) {
  .introfrutiger {
    right: 20px;
    top: 20px;
  }
}
.intrologo {
  float: left;
  width: 816px;
  height: auto;
  max-width: calc(100% - 80px);
  margin: 200px 0;
}
@media (max-width: 767px) {
  .intrologo {
    max-width: calc(100% - 40px);
  }
}
.introclaim {
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 66px;
  z-index: 2;
  color: #D1E0B1;
  text-align: center;
  font-size: 26px;
  line-height: 1.15384615;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  padding: 0 40px;
}
@media (max-width: 767px) {
  .introclaim {
    font-size: 18px;
    line-height: 1.44444444;
    bottom: 140px;
    padding: 0 20px;
  }
}
.fba-legend {
  float: left;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 16px;
  line-height: 1.5;
}
@media (max-width: 767px) {
  .fba-legend {
    display: block;
  }
}
.fba-legend__part {
  float: left;
  width: calc((100% - (3*28px)) / 4);
}
@media (max-width: 767px) {
  .fba-legend__part {
    width: 100%;
    margin-top: 20px;
  }
  .fba-legend__part:first-child {
    margin-top: 0;
  }
}
.fba-legend__item {
  float: left;
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-top: 10px;
}
.fba-legend__item:first-child {
  margin-top: 0;
}
.fba-legend__number {
  float: left;
  flex-shrink: 0;
  margin-right: 10px;
  width: 24px;
  height: 24px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10000px;
  color: #fff;
  font-weight: normal;
  font-family: 'MaisonNeueDemi', sans-serif;
}
.fba-legend__part--1 .fba-legend__number {
  background-color: #711C2F;
}
.fba-legend__part--2 .fba-legend__number {
  background-color: #E11B51;
}
.fba-legend__part--3 .fba-legend__number {
  background-color: #BF797A;
}
.fba-legend__part--4 .fba-legend__number {
  background-color: #F5C7C3;
  color: #711C2F;
}
.fba-legend__name {
  float: left;
}
/*# sourceMappingURL=./screen-small.css.map */